:root {
    --primary: #1972F9;
}

.dropdown-menu {
    background: #000000;
    width: fit-content;
    padding: 0.5rem;
    position: absolute;
    top: 67px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    background: #000000;
    cursor: pointer;
}

.dropdown-menu li:hover {
    border: 2px solid var(--primary);
    border-radius: 10px;
    transition: all 0.2s ease-out;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: rgb(255, 255, 255);
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Jacques Francois-Regular", Helvetica;
    white-space: nowrap;
}

@media screen and (max-width: 960px) {
    .fa-caret-down {
        display: none;
    }

    .dropdown-link {
        justify-self: left;
    }
}