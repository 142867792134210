.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 100%;
    max-width: 100%;
    min-height: 70vh;
    justify-content: center;
    align-items: center;

}

.sidebar {
    /* flex: 30%; */
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding-right: 40px;
    padding-left: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 225px;
    flex-shrink: 0;
    /* overflow: auto; */
    /* position: fixed */
}


.dashboard {
    background-color: #f5f4f8cc;
    flex-grow: 1;
    flex: 75%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.sidebarTitle {
    height: 25px;
    width: 100%;
    list-style-type: none;
    color: #5c9bfb;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
}

.sidebarSpace {
    height: 10%;
}

.referral {
    margin-top: 40%;
    align-items: flex-start;
    background-color: #e7e2f8;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 15px;
    width: 90%;
    height: fit-content;
}

.referral h3 {
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
}

.referral p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 0px;
}

.sidebarList {
    /* height: 75%; */
    padding: 0;
    width: 100%;
    /* background-color: #5C9BFB; */
}

.sidebarList .row {
    height: 10px;
    width: 100%;
    list-style-type: none;
    letter-spacing: 0;
    line-height: normal;
    display: block;
    padding: 10px;
    cursor: pointer;
    color: #88ade5;
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    margin-left: 40px;
}

.sidebarList .rows {
    height: 10px;
    width: 100%;
    list-style-type: none;
    letter-spacing: 0;
    line-height: normal;
    display: block;
    padding: 10px;
    cursor: pointer;
    color: #1972F9;
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    margin-left: 40px;
}

/* .sidebarList .row :hover {
    background-color: #e7e2f8;
    border-radius: 8px;
} */

.sidebarTitleOpen {
    background-color: #e7e2f8;
    border-radius: 8px;
    height: 25px;
    width: 100%;
    list-style-type: none;
    color: #69eb12;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
}

/* .sidebarItem :focus {
    background-color: #e7e2f8;
    border-radius: 8px;
} */

/* .sidebarItemOpen .sidebarContent {
    padding-top: 5px;
    height: 0;
    overflow: hidden;
} */

.sidebarItemOpen .sidebarContent {
    padding-top: 0px;
    margin-top: 0px;
    height: fit-content;
    /* background-color: #5C9BFB; */
}

.sidebarItemOpen {
    /* background-color: #e70a0a; */
    height: auto;
}

.sidebarItem {
    display: block;
    height: 50px;
}

/* .sidebarTitle :hover {
    background-color: #e7e2f8;
    border-radius: 8px;
}

.sidebarTitleOpen :hover {
    background-color: #e7e2f8;
    border-radius: 8px;
} */

/* .sidebarItemOpen :hover {
    background-color: #e7e2f8;
    border-radius: 8px;
} */

.sidebarImg {
    height: 7%;
    margin-bottom: 20px;
}

.sidebarImg img {
    height: 43px;
    object-fit: cover;
    width: 95px;
}

.sidebarItem img {
    flex: 13%;
}

.spc {
    flex: 6%
}

.sidebarTitleOpen .content {
    color: #1972F9;

}

.content {
    flex: 81%;
    color: #5C9BFB;
}

.rowOne {
    display: flex;
    flex-direction: row;
    /* right: 0; */
    height: 50px;
    width: 100%;
    margin-top: 40px;
    margin-right: -100px;
    padding-right: 40px;
    /* position: absolute; */
    /* margin-right: 0px; */
}

.rounded {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid black;
    height: 15px;
    width: 15px;
    justify-content: center;
    display: block;
    z-index: 2;
    text-align: center;
    color: #e70a0a;
    font-family: "Inter-Bold", Helvetica;
    font-size: 10px;
    font-weight: 700;
    cursor: pointer;
}

.notification {
    position: relative;
    margin: auto;
    cursor: pointer;
    width: 28px;
    height: 39px;
    flex: 3%;
}

.notification img {
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.vr {
    border-left: 2px solid rgb(179, 173, 173);
    height: 50px;
    /* position: absolute; */
    /* left: 30%; */
    width: 5%;
    margin-left: 15px;
    flex: 1%;

}

.roundeds {
    border-radius: 50%;
    margin: auto;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    height: 44px;
    width: 44px;
    margin-left: 1px;
    flex: 5%;
    cursor: pointer;
}

.user {
    margin-left: 10px;
    /* margin-right: 10px; */
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 12px;
    font-weight: 700;
    flex: 10%;
    cursor: pointer;
}

.headerOne {
    margin-left: 50px;
    color: #1972f9;
    font-family: "Inter-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 250px;
    margin-top: 30px;
}

.space {
    flex: 81%;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowA {
    flex: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 50px;

}

.rowB {
    flex: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: #e70a0a; */
}

.rowAOne {
    flex: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}

.rowATwo {
    flex: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.one {
    /* flex: 25%; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 130px;
    width: 165px;
    padding: 15px;
    justify-content: center;
}


.one img {
    height: 36px;
    object-fit: fill;
    width: 35px;
}


.two img {
    height: 36px;
    object-fit: fill;
    width: 35px;
}

.three img {
    height: 36px;
    object-fit: fill;
    width: 35px;
}

.two {
    /* flex: 25%; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 130px;
    width: 165px;
    padding: 15px;
}

.three {
    /* flex: 25%; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 130px;
    width: 165px;
    padding: 15px;
}

.card {
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 165px;
    width: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px;
    margin: 15px auto;
    text-align: center;
}

.topup {
    background-color: #82aff3;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 34px;
    width: 213px;
    border-style: none;
    color: "white";
    font-family: "Inter-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: "normal";
    margin: auto;
    cursor: pointer;
    display: block;
}

.topup:hover {
    background-color: #5c9bfb;
}

.charts {
    height: 328px;
    width: 644px;
    margin-top: 60px;
    margin-left: 50px;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 20px;
}

.dashbold {
    background-color: #f5f4f8cc;
    flex-grow: 1;
    flex: 75%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dashbod {
    background-color: #f5f4f8cc;
    flex-grow: 1;
    flex: 75%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cad {
    background-color: inherit;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    height: 870px;
    width: 788px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px;
}

.cald {
    background-color: inherit;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    height: fit-content;
    width: 788px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 50px 50px;
}

.cadd {
    background-color: inherit;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    height: 100%;
    width: 890px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 20px;
}

.slider {
    height: 48px;
    width: 602px;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    ;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sms {
    width: 640px;
    margin: 0px auto;
    /* background-color: #e70a0a; */
    height: fit-content;
}

.template {
    width: 640px;
    margin: 0px auto;
    /* background-color: #e70a0a; */
    height: fit-content;
}

.template h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 371px;
    height: 10px;
    margin-top: 18px;
    font-size: 20px;
    /* margin-left: 70px; */
}

.temp {
    width: 840px;
    margin: 0px auto;
    /* background-color: #e70a0a; */
    height: fit-content;
}

.temp h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 371px;
    height: 10px;
    margin: 18px auto;
    text-align: center;
    font-size: 20px;
    /* margin-left: 70px; */
}

.sms h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 371px;
    height: 10px;
    margin-top: 18px;
    /* margin-left: 70px; */
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: left;
    width: inherit;
    margin: 0px auto;
    height: 800px;
    /* background-color: #82aff3; */
}

.formz {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: left;
    width: inherit;
    margin: 0px auto;
    height: 800px;
    /* background-color: #82aff3; */
}

.formc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: left;
    width: inherit;
    margin: 0px auto;
    height: 800px;
    /* background-color: #82aff3; */
}

.formt {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: left;
    width: inherit;
    margin: 0px auto;
    height: 800px;
    /* background-color: #82aff3; */
}

.form input[type=text] {
    /* padding: 3px 20px; */
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    /* display: block; */
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    /* position: relative; */
    width: 602px;
    padding-left: 20px;
}

.formz input[type=text] {
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 36px;
    position: relative;
    width: 602px;
    padding-left: 20px;
}

.form input[type=tel] {
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 4px 4px #00000040;
    height: 96px;
    position: relative;
    width: 602px;
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    padding-left: 20px;
}

::placeholder {
    letter-spacing: 0;
    line-height: normal;
    /* text-align: justify; */
    white-space: nowrap;
    color: #aaa5a5;
    font-family: "Inter-Thin", Helvetica;
    font-size: 13px;
    font-weight: 100;
}

.form textarea {
    border-style: none;
    padding: 12px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 313px;
    width: 602px;
}

.formz textarea {
    border-style: none;
    padding: 12px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 313px;
    width: 602px;
}

.formc textarea {
    border-style: none;
    padding: 12px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 313px;
    width: 602px;
}

.formt textarea {
    border-style: none;
    padding: 12px;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 313px;
    width: 602px;
}


label {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;

}

.sendNow {
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    width: fit-content;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
}

.sentNow {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 30px;
    width: 215px;
    text-align: center;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    margin-top: 0px;
}

.sendNow label {
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
}

.sendLater {
    background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    width: fit-content;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    /* margin-left: 50px; */
}

.send {
    margin: 60px auto;
}

.sendButton {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 32px;
    width: 612px;
    border-style: none;
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
}

.sendLater label {
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
}

.form h4 {
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: normal;
    height: 26px;
    margin-bottom: 0px;
    margin-top: 5px;
    /* background: rgb(219, 11, 11); */
}

.formc h4 {
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: normal;
    height: 26px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.rightButton {
    color: #ffffff;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    background-color: #5c9bfb;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    width: 168px;
    border-style: none;
    margin-top: 20px;
}

.right {
    width: inherit;
    height: fit-content;
    display: flex;
    justify-content: right;
}

.form input[type=checkbox] {
    /* background-color: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 4px 4px #00000040;
    height: 96px;
    position: relative;
    width: 602px;
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    padding-left: 20px; */
}

.sliderButton {
    background-color: #ffffff;
    border-radius: 28px;
    box-shadow: 0px 4px 4px #00000040;
    height: 25px;
    width: 177px;
    border-style: none;
    margin: auto;
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 600;

}

.sliderButtonActive {
    background-color: #5c9bfb;
    border-radius: 28px;
    box-shadow: 0px 4px 4px #00000040;
    height: 25px;
    width: 177px;
    border-style: none;
    margin: auto;
    color: #000000;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15px;
    font-weight: 600;
}

.category {
    width: 640px;
    margin: 0px auto;
    height: 690px;
}

.category h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 371px;
    height: 10px;
    margin-top: 18px;
}

.formc input[type=text] {
    /* padding: 3px 20px; */
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    /* display: block; */
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    position: relative;
    width: 602px;
    padding-left: 20px;
}

.formt input[type=text] {
    /* padding: 3px 20px; */
    border-style: none;
    border-radius: 10px;
    justify-content: left;
    /* display: block; */
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 47px;
    position: relative;
    width: 602px;
    padding-left: 20px;
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    height: fit-content;
    width: inherit;
    justify-content: space-around;
}

.successRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    height: 40px;
}

.successRow img {
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.successRow h1 {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 137px;
    height: 37px;
    margin: 7px auto;
}

.hr {
    width: 80%;
    height: 1px;
    background: #000;
    margin: 10px auto;
}

.homePageTwoTable {
    height: fit-content;
    width: 700px;
    margin: 70px auto;
    border-color: #aba9a9;
    background-color: #ffffff;
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    border-radius: 25px;
    box-shadow: 0px 4px 15px #00000040;
}

.homePageTwoTabler {
    height: fit-content;
    width: 700px;
    margin: 40px auto;
    border-color: #aba9a9;
    background-color: #ffffff;
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    border-radius: 25px;
    box-shadow: 0px 4px 15px #00000040;
}

.homePageTwoTables {
    height: fit-content;
    width: 700px;
    margin: 40px auto;
    /* border-color: #aba9a9; */
    background-color: #ffffff;
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    box-shadow: 0px 4px 15px #00000040;
    /* border: 1px solid; */
    border-radius: 25px 25px 0px 0px;
}

.homePageTwoTable th {
    text-align: center;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    background-color: inherit;
    color: #000;
    height: 37px;
}

.homePageTwoTabler th {
    text-align: center;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    background-color: inherit;
    color: #000;
    height: 37px;
}

.homePageTwoTables th {
    text-align: center;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 15px;
    font-weight: 600;
    background-color: inherit;
    color: #000;
    height: 37px;
}

.homePageTwoTable tr {
    /* line-height: 3.5em; */
    /* height: 37px; */
}

.cad h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
}

input[type=date] {
    height: 80%;
    width: 80%;
    margin: auto;
    outline: none;
    cursor: pointer;
}