.hideModal {
    display: none;
}

.modal {
    position: absolute;
    z-index: 2;
    right: 0;
    display: flex;
    top: 0;
    background: #fcfcfc;
    backdrop-filter: blur(75px);
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
    border-bottom: 2.5px solid red;
    max-width: 30%;
    width: fit-content;
    right: 10px;
    animation: error 3.5s cubic-bezier(0.2, 0.5, 0.5, 1.3) 1 normal forwards;
}

@keyframes error {
    0% {
        right: -23%;
        top: 0.2%;
    }

    50% {
        right: 1%;
        top: 0.2%;
    }

    100% {
        right: 1%;
        top: 0%;
    }
}

.modal img {
    margin-right: 1em;
    width: 1.5em;
    height: 1.5em;
}

.content h3,
.content p {
    margin: 0;
    font-family: 'Inter-Regular', Helvetica;
}

.content h3 {
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.content p {
    font-size: 0.8rem;
}

.modalSuccess {
    position: absolute;
    z-index: 1;
    right: 0;
    display: flex;
    top: 0;
    background: #fcfcfc;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
    border-bottom: 2.5px solid #1972F9;
    max-width: 30%;
    width: fit-content;
    right: 10px;
    animation: error 3.5s cubic-bezier(0.2, 0.5, 0.5, 1.3) 1 normal forwards;
}

.modalSuccess img {
    margin-right: 1em;
    width: 1.5em;
    height: 1.5em;
}

@media only screen and (max-width:500px) {

    .modalSuccess,
    .modal {
        width: 90%;
        margin-top: 2rem;
    }
}