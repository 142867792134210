.containerA {
    display: flex;
    margin: 0rem auto;
    padding: 1rem;
    width: 90%;
    max-width: 90%;
    justify-content: center;
    align-items: center;
}

.containerB {
    display: flex;
    margin: 0rem auto;
    padding: 1rem;
    width: 90%;
    max-width: 90%;
    justify-content: center;
    align-items: center;
}

.cardA {
    background-color: #f7f7f7;
    border-radius: 10px;
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 47%;
    max-width: 47%;
    min-height: 60vh;
    margin-bottom: 5rem;
}

.cardA h3 {
    color: #000000;
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 2rem;
    font-family: "Inter-SemiBold", Helvetica;
}

::placeholder {
    font-size: 0.9rem;
}

.cardA p {
    color: #000000;
    font-weight: 400;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    color: rgb(0, 0, 0);
    margin-bottom: 2rem;
}

.textLink {
    text-decoration: none;
}

.form {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.75rem;
}

.form input[type=email] {
    background-color: #ffffff;
    border: 1px solid #fa7575;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 1.75rem 0.5rem;
    margin-bottom: 0rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=text] {
    background-color: #ffffff;
    border: 1px solid #fa7575;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 1.75rem 0.5rem;
    margin-bottom: 0rem;
    margin-top: 0.5rem;
    text-align: left;
    cursor: pointer;
}


.passwordInput {
    background-color: #ffffff;
    backdrop-filter: blur(71px);
    border: 1px solid #fa7575;
    border-radius: 10px;
    margin-bottom: 0.6rem;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0.75rem;
}

.passwordInput i {
    color: #000000;
    font-size: 1rem;
    /* background-color: crimson; */
}

.passwordInputText {
    /* background: #1972f9; */
    backdrop-filter: none !important;
    border: none !important;
    border-radius: 0 !important;
    width: 100%;
    max-width: 100%;
    /* height: inherit !important; */
    padding: 0px !important;
    color: #000000;
    outline: none !important;
}

.form input[type=tel] {
    background-color: #ffffff;
    border-radius: 10px;
    border-radius: 20px;
    width: 100%;
    max-width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=submit] {
    background-color: #1972f9;
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 500;
    text-align: center;
    font-size: 1.25rem;
    padding: 1.25rem 4rem;
    color: #ffffff;
    cursor: pointer;
    width: 95%;
    max-width: 95%;
    margin-top: 0rem;
}

.form p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 2rem;
    text-align: center;
    color: #fa7575;
}

label {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.cardRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.hr {
    width: 35%;
    max-width: 35%;
    margin-top: 0.5rem;
    height: 0px;
}

.cardB {
    background-color: #f7f7f7;
    border-radius: 10px;
    display: flex;
    padding: 1.5rem 2rem;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 90%;
    margin-bottom: 5rem;
}

.cardBContent {
    width: 48%;
    max-width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
}

.cardBContent h3 {
    font-size: 3rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 2rem;
    text-align: left;
    font-family: "Inter-SemiBold", Helvetica;
}

.cardBContent p {
    font-size: 0.95rem;
    text-align: left;
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-weight: 600;
}

.cardBForm {
    width: 46%;
    max-width: 46%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    background-color: #ebeaea42;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    padding: 1rem 0.8rem;
}

.cardBImage {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.cardBImage img {
    width: 68%;
    max-width: 68%;
    object-fit: contain;
}

.textLinks {
    text-decoration: none;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1rem;
    font-weight: 600;
    color: #1972f9;
}

.lastP {
    margin: 1rem auto;
    width: 95%;
    font-size: 0.8rem;
    font-weight: 400;
}

.required h4 {
    font-size: 0.7rem;
}

.requir h4 {
    font-size: 0.7rem;
    color: #000000;
}

.required h4::before {
    content: "*";
    color: #ff0000;
}

.googleButton {
    display: block;
    margin: 1rem auto;
    background-color: #ffffff;
    color: #000000;
    border-style: none;
    border-radius: 5px;
    width: 90%;
    font-size: 1.25rem;
    font-weight: 400;
    cursor: pointer;
    padding: 1rem;
    white-space: nowrap;
}

@media screen and (max-width: 760px) {
    .cardBImage {
        display: none;
    }

    .cardB {
        flex-direction: column;
    }

    .cardBContent {
        width: 100%;
        max-width: 100%;
    }

    .cardBContent h3 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    .cardBContent p {
        font-size: 1rem;
        margin-top: 0rem;
    }

    .cardBForm {
        width: 77.5%;
        max-width: 80%;
        margin-top: 3rem;
    }


}