.container {
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    width: 100%;
    max-width: 100%;
    min-height: 70vh;
    justify-content: center;
    align-items: center;
}

.rowOne {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    max-width: 25%;
}

.rowOne button {
    margin: 0.5rem auto;
    border-style: none;
    border-radius: 10px;
    padding: 0.75rem 4rem;
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.rowOne button:active {
    background-color: #eaeaeae0;
}

.rowOne button:focus {
    background-color: #eaeaeae0;
}

.rowTwo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 65%;
    max-width: 65%;
}

.rowTwo h3 {
    margin: 0px auto;
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.566rem;
    font-weight: 700;
}

.rowTwo p {
    margin-top: 0.5rem;
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-left: 3rem;
}

.vr {
    border-left: 2px dotted rgb(179, 173, 173);
    min-height: 100vh;
    width: 1%;
}