.container {
    display: flex;
    margin: 1rem 3rem;
    padding: 2rem;
    width: 90%;
    max-width: 90%;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    width: 42.5%;
    max-width: 42.5%;
    padding: 2.75rem;
    margin-bottom: 4rem;
}

.card h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
}

.card p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 0.9rem;
    color: rgb(0, 0, 0);
    margin-bottom: 1rem;
}

.logImage {
    width: 45%;
    max-width: 45%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
}

.logImage img {
    width: 70%;
    max-width: 70%;
    object-fit: contain;
}

.logLogo {
    width: 45%;
    max-width: 45%;
    max-height: 10vh;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logLogo img {
    width: 90%;
    max-width: 100%;
    object-fit: contain;
}

.form {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
}

.form input[type=email] {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 15px;
    width: 100%;
    max-width: 100%;
    padding: 0.9rem;
    margin-bottom: 1rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=password] {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 15px;
    width: 100%;
    max-width: 100%;
    padding: 0.9rem;
    margin-bottom: 1rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=submit] {
    background-color: #1972f9;
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.1rem;
    padding: 0.8rem 4rem;
    white-space: nowrap;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
}

.form p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 0.6rem;
    text-align: left;
    padding-left: 0.7rem;
    color: tomato;
}

.textLinks {
    text-decoration: none;
    font-family: "Inter-Regular", Helvetica;
    font-size: 0.9rem;
    font-weight: 600;
    color: #1972f9;
}

@media screen and (max-width: 760px) {
    .container {
        min-height: 75vh;
    }

    .logImage {
        display: none;
        margin-left: 0;
    }

    .card {
        margin-left: -2.5%;
        width: 85%;
        max-width: 85%;
        padding: 2rem;
    }

    .card h3 {
        color: #000000;
        font-family: "Inter-Bold", Helvetica;
        font-size: 1.5rem;
        font-weight: 800;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .logLogo {
        width: 45%;
        max-width: 45%;
        max-height: 5vh;
        margin-bottom: 1rem;
    }

    .logLogo img {
        width: 90%;
        max-width: 100%;
        object-fit: contain;
    }


}