.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.textLink {
    text-decoration: none;
}

.menuOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.menuOneBody {
    width: 49%;
    max-width: 49%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.menuOneBody h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.94rem;
    font-weight: 900;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
    text-align: left;
}

.menuOneImage {
    width: 48%;
    max-width: 48%;
    /* height: 100%; */
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuOneImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.menuOneBody p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
}

.menuOneBody button {
    background-color: #1972f9;
    border-radius: 5px;
    border-style: none;
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.25rem;
    padding: 1.3rem 3rem;
    margin: 4rem auto;
    white-space: nowrap;
    display: block;
}

.menuTwo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 3rem;
    align-items: center;
    width: 100%;
}

.menuTwo h1 {
    text-align: left;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
}

.menuTwoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.mtrContent {
    width: 58%;
    max-width: 58%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.mtrContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.contentImage {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-self: right;
    justify-content: flex-end;
    margin-top: 1rem;
}

.contentImage img {
    width: 80%;
    max-width: 80%;
    object-fit: contain;
    border-radius: 20%;
}

.mtrImage {
    width: 40%;
    max-width: 40%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mtrImage img {
    width: 90%;
    max-width: 90%;
    object-fit: contain;
}

.cardOne {
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    max-width: 100%;
    padding: 2rem;
}

.cardImage {
    width: 50%;
    max-width: 50%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardImage img {
    width: 70%;
    max-width: 70%;
    object-fit: contain;
}

.cardContent {
    width: 50%;
    max-width: 50%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.cardContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.cardContent p {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: left;
}

.cardTwo {
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    max-width: 100%;
    padding: 2.5rem 1.5rem;
    margin-bottom: 3rem;
}

.cardTwoContent {
    width: 48%;
    max-width: 48%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.cardTwoContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
}

.cardTwoContent p {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.cardTwoImage {
    width: 46%;
    max-width: 46%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTwoImage img {
    width: 95%;
    max-width: 95%;
    object-fit: contain;
}

.healthButton {
    background-color: #1972f9;
    border-radius: 5px;
    border-style: none;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.88rem;
    padding: 1.3rem 3rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    white-space: nowrap;
    display: block;
}

.labButtonMobile {
    display: none;
}

.labButtonMobiles {
    display: none;
}

@media screen and (max-width: 760px) {
    .menuOne {
        margin-top: 1rem;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: .5rem;
    }

    .menuOneBody {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: flex-start;
    }

    .menuOneBody h1 {
        color: #000000;
        font-family: "Inter-ExtraBold", Helvetica;
        font-size: 2.94rem;
        font-weight: 900;
        margin-top: 0rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }

    .menuOneBody button {
        display: none;
    }

    .labButtonMobile {
        display: block;
        margin: 1rem auto;
        background-color: #000000;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 25rem;
        font-size: 1.75rem;
        font-weight: 700;
        cursor: pointer;
        padding: 2rem 1rem;
    }

    .labButtonMobiles {
        display: block;
        margin: 1rem auto;
        background-color: #1972F9;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 20rem;
        font-size: 1.75rem;
        font-weight: 700;
        cursor: pointer;
        padding: 2rem 0rem;
    }

    .menuOneImage {
        margin-top: 2rem;
        width: 68%;
        max-width: 80%;
        margin-bottom: 1.5rem;
    }

    .menuOneImage img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .menuTwo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 2.5rem;
        align-items: center;
        width: 100%;
    }

    .mtrContent {
        width: 100%;
        max-width: 100%;
        min-height: inherit;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .contentImage {
        width: 80%;
        max-width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }

    .contentImage img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
        border-radius: 20%;
    }

    .mtrImage {
        display: none;
    }

    .cardOne {
        margin-top: 3rem;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        border-radius: 40px;
        box-shadow: 0px 4px 4px #00000040;
        min-width: 100%;
        max-width: 100%;
        padding: 2rem;
    }

    .cardContent {
        width: 100%;
        max-width: 100%;
    }

    .cardContent h1 {
        margin-top: 3rem;
        color: #000000;
        font-family: "Inter-ExtraBold", Helvetica;
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 2rem;
        text-align: center;
    }

    .cardContent p {
        color: #000000;
        font-family: "Inter-Bold", Helvetica;
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .cardImage {
        width: 100%;
        max-width: 100%;
        margin-top: 1.5rem;
    }

    .cardImage img {
        width: 60%;
        max-width: 70%;
        object-fit: contain;
    }

    .cardTwo {
        margin-top: 3rem;
        background-color: #ffffff;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .cardTwoContent {
        width: 100%;
        max-width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cardTwoContent h1 {
        color: #000000;
        font-family: "Inter-ExtraBold", Helvetica;
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 2rem;
    }

    .cardTwoContent p {
        color: #000000;
        font-family: "Inter-Bold", Helvetica;
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .cardTwoImage {
        width: 100%;
        max-width: 100%;
        margin-top: 1.5rem;
    }

    .cardTwoImage img {
        width: 80%;
        max-width: 95%;
        object-fit: contain;
    }

    .healthButton {
        margin-top: 0rem;
        margin-bottom: 4rem;
    }
}