.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 90%;
    max-width: 90%;
}

.menuItem {
    margin-top: 0.25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0;
}

.homePageOneContent {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* margin-top: 2rem;
    margin-bottom: 2rem; */
    margin-right: 0;
    margin-top: 0;
}

.homePageOneContent h1 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 3rem;
    font-weight: 800;
    text-align: left;
}

.homePageOneContent p {
    color: #000000;
    font-family: "Inter-Semi-bold", Helvetica;
    font-size: 1.19rem;
    font-weight: 500;
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.homePageOneImage {
    width: 45%;
    max-width: 45%;
    height: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.homePageOneImage img {
    width: 125%;
    max-width: 130%;
    object-fit: contain;
}

.labButton2 {
    background-color: #000000;
    color: #ffffff;
    border-style: none;
    display: block;
    margin: 0px auto;
    width: 15rem;
    height: 3.3rem;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.menuu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
}

.menuu h1 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 3.1rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.menuu p {
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.menuIte {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.menuImage {
    width: 47%;
    max-height: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuImage img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.menuContent {
    width: 47%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
    margin-top: 2.5rem;
}

.menuContent h2 {
    color: #122EBF;
    font-size: 2.6rem;
    font-weight: 700;
    font-family: "Inter-Bold", Helvetica;
    margin-bottom: 0rem;
}

.menuContent p {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    font-size: 1.22rem;
    font-weight: 500;
    font-family: "Inter-Regular", Helvetica;
}

.menuTwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
}

.menuTwoContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
    text-align: left;
}

.menuTwoContent h2 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.5rem;
    font-weight: 700;
}

.menuTwoContent p {
    font-family: "Inter-Regular", Helvetica;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.22rem;
}

.menuTwoImage {
    width: 49%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuTwoImage img {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
}

.textLink {
    text-decoration: none;
}

.labButton3 {
    background-color: #1972F9;
    color: #ffffff;
    border-style: none;
    padding: 0.75rem 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 15px;
    font-family: "Inter-SemiBold", Helvetica;
}

.menuThree {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
}

.homePageOneImage {
    width: 40%;
    height: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuThreeImage {
    width: 50%;
    /* padding-bottom: 2.5rem; */
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuThreeImage img {
    object-fit: contain;
    max-width: 100%;
    height: 100%;
}

.menuThreeContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    text-align: left;
    /* padding: 1.5rem; */
}

.menuThreeContent h2 {
    color: #122EBF;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.5rem;
    font-weight: 700;
}

.menuThreeContent p {
    font-family: "Inter-Regular", Helvetica;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.22rem;
}

.menuFour {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
}

.menuFourContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    text-align: left;
}

.menuFourContent h2 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.5rem;
    font-weight: 700;
}

.menuFourContent p {
    font-family: "Inter-Regular", Helvetica;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.22rem;
}

.menuFourImage {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuFourImage img {
    object-fit: contain;
    max-width: 100%;
}

.headerOne {
    margin-top: 6rem;
    padding-left: 1.8rem;
    text-align: left;
    margin-bottom: 0;
}

.headerOne h1 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.23rem;
    font-weight: 700;
    text-align: left;
    text-shadow: 2px 2px 2px #b4adad;
}

.menuFive {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 1.5rem;
    text-align: center;
}

.miniMenu {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    justify-content: flex-start;
}

.miniMenu h3 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 0.5rem;
    text-shadow: 1.5px 1.5px 1.5px #b4adad;
}

.miniMenu p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 200;
    text-align: left;
}

.miniMenuImage {
    width: 100%;
    display: flex;
    justify-content: center;
}

.miniMenuImage img {
    object-fit: contain;
    max-width: 100%;
}

.headerTwo {
    margin-top: 3rem;
}

.headerTwo h1 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.9rem;
    font-weight: 700;
}

.menuSix {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0rem;
    text-align: center;
    background-image: url("../../../assets/background.svg");
}

.menuSixContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding: 1.5rem;
}

.menuSixContent h2 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2rem;
    font-weight: 700;
    padding: 2.5rem;
}

.menuSixImage {
    width: 45%;
    padding-bottom: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuSixImage img {
    object-fit: contain;
    max-width: 100%;
}

.button4 {
    background-color: #000000;
    color: #ffffff;
    border-style: none;
    padding: 0.75rem 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 5px;
    font-family: "Inter-SemiBold", Helvetica;
    margin: 0px auto;
    display: block;
}

.labButtonMobile {
    display: none;
}

.labButtonMobiles {
    display: none;
}

@media screen and (max-width: 760px) {
    .menuItem {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 0;
    }


    .homePageOneContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: fit-content;
    }

    .homePageOneContent h1 {
        font-family: "Inter-Bold", Helvetica;
        font-size: 3.5rem;
        font-weight: 800;
        text-align: left;
        margin-bottom: 0;
        margin-top: 2.5vh;
    }

    .homePageOneContent p {
        color: #000000;
        font-family: "Inter-Semi-bold", Helvetica;
        font-size: 1.35rem;
        font-weight: 500;
        text-align: left;
        margin-top: 0;
        margin-bottom: 0;
    }

    .homePageOneImage {
        width: 100%;
        max-width: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
    }

    .homePageOneImage img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
    }


    .menuIte {
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
    }

    .menuImage {
        width: 100%;
        max-width: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .menuImage img {
        object-fit: contain;
        width: 100%;
        max-width: 100%;
    }

    .menuContent {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 3rem;
    }

    .menuContent h2 {
        color: #122EBF;
        font-size: 3rem;
        font-weight: 800;
        font-family: "Inter-Bold", Helvetica;
        margin-bottom: 0;
    }

    .menuContent p {
        font-size: 1.25rem;
        font-weight: 500;
        font-family: "Inter-Regular", Helvetica;
    }

    .menuTwo {
        width: 100%;
        margin-top: 2rem;
        text-align: center;
        flex-direction: column;
        justify-content: flex-start;
    }


    .menuTwoContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        padding: 1.5rem;
    }

    .labButton2 {
        display: none;
    }

    .labButtonMobile {
        display: block;
        margin: 1rem auto;
        background-color: #000000;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 25rem;
        font-size: 1.75rem;
        font-weight: 700;
        cursor: pointer;
        padding: 2rem 1rem;
    }

    .labButtonMobiles {
        display: block;
        margin: 1rem auto;
        background-color: #1972F9;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 20rem;
        font-size: 1.75rem;
        font-weight: 700;
        cursor: pointer;
        padding: 2rem 0rem;
    }

    .labButton3 {
        display: none;
    }

    .menuTwoImage {
        width: 80%;
        max-width: 85%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 1rem;
    }

    .menuTwoImage img {
        object-fit: contain;
        width: 90%;
        max-width: 90%;
        height: 100%;
    }

    .menuThree {
        flex-direction: column-reverse;
        justify-content: flex-start;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 0rem;
    }

    .menuThreeContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 100%;
        text-align: left;
    }

    .menuThreeContent h2 {
        margin-top: 2rem;
        font-size: 3.5rem;
        font-weight: 700;
    }

    .menuThreeContent p {
        font-family: "Inter-Regular", Helvetica;
        font-weight: 500;
        font-size: 1.25rem;
        margin-bottom: 0rem;
    }

    .menuThreeImage {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
        margin-top: 0rem;
    }

    .menuThreeImage img {
        object-fit: contain;
        max-width: 100%;
        height: 100%;
    }

    .menuFour {
        margin-top: 0rem;
        margin-bottom: 0rem;
        width: 100%;
        text-align: center;
    }

    .headerOne {
        margin-top: 2rem;
    }

    .headerOne h1 {
        font-size: 2.5rem;
    }

    .menuFive {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .miniMenu {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        padding: 0;
        margin-bottom: 1rem;
        justify-content: center;
        align-items: center;
    }

    .miniMenu h3 {
        font-size: 2.25rem;
        text-align: left;
        margin-bottom: 0rem;
    }

    .miniMenu p {
        font-size: 1.35rem;
        font-weight: 500;
        font-family: "Inter-Bold", Helvetica;
    }

    .miniMenuImage {
        justify-content: center;
    }

    .miniMenuImage img {
        object-fit: contain;
        width: 70%;
        max-width: 70%;
    }

    .headerTwo {
        margin-top: 3rem;
        margin-bottom: 0rem;
    }

    .headerTwo h1 {
        text-align: left;
        font-size: 2.25rem;
    }

    .menuSix {
        margin-top: 0rem;
        padding-top: 0rem;
    }



}