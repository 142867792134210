.form {
    width: 95%;
    max-width: 95%;
    margin-bottom: 0.75rem;
}

.form input[type=email] {
    background-color: #ffffff;
    border: 1px solid #fa7575;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 0.9rem;
    margin-bottom: 0.6rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=text] {
    background-color: #ffffff;
    border: 1px solid #fa7575;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 0.9rem;
    margin-bottom: 0.6rem;
    text-align: left;
    cursor: pointer;
}

/* .form input[type=password] {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    width: 100%;
    max-width: 100%;
    padding: 1rem 0.9rem;
    margin-bottom: 0.75rem;
    text-align: left;
    cursor: pointer;
} */

.form input[type=tel] {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #000000;
    border-radius: 10px;
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 0.9rem;
    margin-bottom: 0.6rem;
    text-align: left;
    cursor: pointer;
}

.form input[type=submit] {
    background-color: #1972f9;
    border-radius: 5px;
    border: 1px solid #f7f7f7;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 500;
    text-align: center;
    font-size: 1.25rem;
    padding: 1rem 4rem;
    color: #ffffff;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    margin-top: 0rem;
}

.form p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 1rem;
    text-align: left;
    margin-bottom: 1rem;
    color: rgb(0, 0, 0);
}