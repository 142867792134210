.navbar {
    background: #fff;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    cursor: pointer;
    background-image: url(../assets/logo.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 13%;
    max-width: 15%;
    min-height: 10vh;
    margin-left: 2rem;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: center;
    margin-right: 2rem;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-links {
    color: rgb(0, 0, 0);
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Times New Roman", Helvetica;
    white-space: nowrap;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #1972F9;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobileOne {
    display: none;
}

.nav-links-mobileTwo {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .navbar-logo {
        width: 25%;
        max-width: 25%;
        min-height: 10vh;
        margin-left: 1.5rem;
        position: absolute;
        left: 0;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 60vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgb(44, 43, 43);
        padding: 2rem;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        color: rgb(255, 255, 255);
        font-family: "Inter-Regular", Helvetica;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        font-weight: 600;
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 0;
        margin-right: 0.5rem;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .menu-icon i {
        color: black;
        margin-right: 2rem;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobileOne {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 1rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1972F9;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .nav-links-mobileOne:hover {
        background: #fff;
        color: #1972F9;
        transition: 250ms;
    }

    .nav-links-mobileTwo {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 1rem auto;
        border-radius: 4px;
        width: 80%;
        background: #000000;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .nav-links-mobileTwo:hover {
        background: #fff;
        color: #000000;
        transition: 250ms;
    }

    button {
        display: none;
    }

    .nav-item {
        flex-direction: column;
        height: fit-content;
    }
}