.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 90%;
    max-width: 90%;
}

.textLink {
    text-decoration: none;
}

.menuOne {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 7.5vh;
}

.menuOneContent {
    width: 45%;
    max-width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuOneContent h1 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 3.35rem;
    font-weight: 800;
    text-align: left;
}

.menuOneContent p {
    color: #000000;
    font-family: "Inter-Semi-bold", Helvetica;
    font-size: 1.19rem;
    font-weight: 500;
    text-align: left;
    padding: 3.25rem 0;
}

.menuOneImage {
    width: 55%;
    max-width: 55%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.menuOneImage img {
    object-fit: contain;
    max-width: 100%;
}

.roow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.roow img {
    width: 28%;
    object-fit: contain;
    max-width: 30%;
}

.roow h1 {
    width: 68%;
}

.button1 {
    display: block;
    background-color: #1972F9;
    color: #ffffff;
    border-style: none;
    padding: 0.75rem 2rem;
    margin: 0px auto;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 10px;
    font-family: "Inter-SemiBold", Helvetica;
}

.cardOne {
    margin-top: 4rem;
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    padding: 0 0 2.5rem 0;
    margin-bottom: 1rem;
}

.menuu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 3.5rem;
    text-align: center;
}

.cardOne h1 {
    color: #000000;
    font-weight: 800;
    font-family: "Inter-Bold", Helvetica;
    text-align: center;
    font-size: 3.4rem;
    width: 70%;
    padding-top: 4rem;
}

.test {
    color: #000000;
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    padding-top: 2.5rem;
    width: 90%;
}

.bigRow {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.rowCard {
    width: 29%;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}

.rowCar {
    width: 29%;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}

.rowCa {
    width: 29%;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}

.imageRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imageRow img {
    width: 30%;
    object-fit: contain;
    max-width: 30%;
}

.imageRow h3 {
    width: 65%;
    color: #122EBF;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 1.5rem;
    font-weight: 650;
    text-align: left;
}

.rowCard p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.13rem;
    font-weight: 400;
    padding-top: 0.8rem;
    text-align: left;
}

.rowCar p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.13rem;
    font-weight: 400;
    padding-top: 0.8rem;
    text-align: left;
}

.rowCa p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.13rem;
    font-weight: 400;
    padding-top: 0.8rem;
    text-align: left;
}

.lastRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0rem 3rem;
}

.lastRow1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    width: 47%;
}

.lastRow1 h3 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.6rem;
    font-weight: 800;
    text-align: left;
}

.lastRow1 p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.13rem;
    font-weight: 400;
    text-align: left;
    margin-top: 3rem;
}

.lastRow2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 47%;
}

.styledList {
    text-align: left;
    padding-top: 1rem;
    padding-left: 1rem;
}

.styledList li {
    color: #000000;
    padding: 0.5rem 0;
    font-size: 1.13rem;
    font-family: "Inter-Regular", Helvetica;
    font-weight: 400;
    line-height: normal;
}

.list {
    text-align: left;
    padding-top: 1rem;
    padding-left: 2.5rem;
}

.list li {
    color: #000000;
    padding: 0.5rem 0;
    font-size: 1.13rem;
    font-family: "Inter-Regular", Helvetica;
    font-weight: 400;
    line-height: normal;
}

.lastRow1 img {
    position: relative;
    width: 70%;
    object-fit: contain;
    align-self: flex-end;
    padding-top: 2rem;
}

.lastRow2 img {
    position: relative;
    width: 85%;
    object-fit: contain;
    align-self: flex-start;
}

.banner {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.banner img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    align-self: flex-start;
}

.bannerTwo {
    margin-top: -8rem;
}

.bannerTwo h2 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.8rem;
    font-weight: 800;
    letter-spacing: 0;
    line-height: normal;
}

.bannerTwo p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 500;
}

.bannerThree {
    margin-top: 2rem;
    width: 80%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerThree img {
    width: 60%;
    object-fit: contain;
    align-self: center;
}

.blackButton {
    background-color: #000000;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.56rem;
    font-weight: 700;
    text-align: center;
    border-style: none;
    padding: 0.75rem 2rem;
    margin: 3rem auto;
    border-radius: 5px;
    display: block;
}

.labButtonMobile {
    display: none;
}

.labButtonMobiles {
    display: none;
}

@media screen and (max-width: 760px) {
    .button1 {
        display: none;
    }

    .menuOne {
        margin-top: 2.5vh;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .menuOneContent {
        width: 100%;
        max-width: 100%;
    }

    .labButtonMobile {
        display: block;
        margin: 1rem auto;
        background-color: #000000;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 25rem;
        font-size: 1.75rem;
        font-weight: 700;
        cursor: pointer;
        padding: 2rem 1rem;
    }

    .labButtonMobiles {
        display: block;
        margin: 1rem auto;
        background-color: #1972F9;
        color: #ffffff;
        border-style: none;
        border-radius: 10px;
        width: 75vw;
        font-size: 1.75rem;
        font-family: 'Inter-Bold', Helvetica;
        font-weight: 600;
        cursor: pointer;
        padding: 1.5rem 0rem;
    }

    .roow {
        width: 100%;
    }

    .roow img {
        width: 30%;
        object-fit: contain;
        max-width: 30%;
    }

    .roow h1 {
        width: 62%;
        font-family: "Inter-Bold", Helvetica;
        font-size: 5rem;
        font-weight: 800;
        text-align: left;
        margin-bottom: 0;
    }

    .menuOneContent p {
        color: #000000;
        font-size: 1.5rem;
        font-weight: 500;
        padding: 0;
        margin-top: 0.5rem;
    }

    .menuOneImage {
        width: 85%;
        max-width: 85%;
        margin-top: 3rem;
    }

    .menuOneImage img {
        object-fit: contain;
        max-width: 100%;
    }

    .cardOne {
        margin-top: 4rem;
    }

    .cardOne h1 {
        color: #000000;
        font-weight: 800;
        font-family: "Inter-Bold", Helvetica;
        text-align: center;
        font-size: 3.4rem;
        width: 85%;
        padding-top: 2rem;
    }

    .test {
        padding: 0;
        margin-top: 0rem;
    }

    .bigRow {
        margin-top: 4rem;
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    .rowCard {
        width: 90%;
        max-width: 90%;
        margin-bottom: 2rem;
        flex-direction: column;
        padding: 1rem;
        border-radius: 20px;
    }

    .rowCard p {
        font-size: 1.25rem;
    }

    .rowCar {
        width: 90%;
        max-width: 90%;
        margin-bottom: 2rem;
        flex-direction: column;
        padding: 1rem;
        border-radius: 20px;
    }

    .rowCar p {
        font-size: 1.25rem;
    }

    .rowCa {
        width: 90%;
        max-width: 90%;
        margin-bottom: 2rem;
        flex-direction: column;
        padding: 1rem;
        border-radius: 20px;
    }

    .rowCa p {
        font-size: 1.25rem;
    }

    .imageRow img {
        width: 35%;
        object-fit: contain;
        max-width: 35%;
    }

    .imageRow h3 {
        width: 60%;
        font-size: 1.75rem;
    }

    .banner {
        margin-top: 1.5rem;
    }

}