.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
}

.textLink {
    text-decoration: none;
}

.menuOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 2.5rem;
}

.menuOneBody {
    width: 56%;
    max-width: 56%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.menuOneBody h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 3rem;
    font-weight: 900;
    margin-top: 0rem;
    margin-bottom: 0.75rem;
    text-align: left;
}

.menuOneBody p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-top: 1rem;
}

.bodyImage {
    margin-top: 0.5rem;
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bodyImage img {
    width: 90%;
    max-width: 90%;
    object-fit: contain;
}

.menuOneImage {
    width: 42%;
    max-width: 42%;
    height: 60%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuOneImage img {
    width: 90%;
    max-width: 90%;
    object-fit: contain;
}

.cardOne {
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 3rem 1.5rem;
}

.cardOne h1 {
    font-size: 2.5rem;
    font-weight: 900;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    margin-bottom: 3rem;
    text-align: left;
}

.cardRow {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.rowContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.rowContent h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    text-align: left;
    margin-bottom: 0rem;
}

.rowContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-top: 2rem;
}

.cardTwo {
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.cardTwoContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.cardTwoContent h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #1972f9;
    font-family: "Inter-ExtraBold", Helvetica;
    text-align: left;
    margin-bottom: 2rem;
}

.cardTwoContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-bottom: 1rem;
}

.cardTwoImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTwoImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardThree {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.cardThreeImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardThreeImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardThreeContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.cardThreeContent h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    text-align: left;
    margin-bottom: 1rem;
}

.cardThreeContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-top: 0rem;
    margin-bottom: 1rem;
}

.financeButton {
    background-color: #1972f9;
    border-radius: 40px;
    border-style: none;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.88rem;
    padding: 0.8rem 4rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    white-space: nowrap;
    display: block;
}

@media screen and (max-width: 760px) {
    .bodyImage {
        display: none;
    }

    .cardOne {
        margin-top: 2.5rem;
    }

    .cardOne h1 {
        font-size: 3rem;
        margin-bottom: 3rem;
    }

    .cardRow {
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .rowImage {
        margin-top: 2rem;
        width: 80%;
        max-width: 80%;
    }

    .rowContent {
        width: 100%;
        max-width: 100%;
    }

    .rowContent h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .rowContent p {
        color: #000000;
        margin-top: 0.5rem;
    }

    .cardTwo {
        flex-direction: column;
        margin-top: 2.5rem;
    }

    .cardTwoContent {
        width: 100%;
        max-width: 100%;

    }

    .cardTwoContent p {
        margin-bottom: 1rem;
    }

    .cardTwoImage {
        margin-top: 2rem;
        width: 80%;
        max-width: 80%;
    }

    .cardThree {
        margin-top: 2.5rem;
        flex-direction: column-reverse;
    }

    .cardThreeImage {
        margin-top: 2rem;
        width: 80%;
        max-width: 80%;
    }

    .cardThreeContent {
        width: 100%;
        max-width: 100%;
    }

    .cardThreeContent h1 {
        font-size: 2.5rem;
        font-weight: 800;
        color: #000000;
        font-family: "Inter-ExtraBold", Helvetica;
        text-align: left;
        margin-bottom: 2.5rem;
    }

    .cardThreeContent p {
        color: #000000;
        font-family: "Inter-Regular", Helvetica;
        font-size: 1.25rem;
        font-weight: 400;
        text-align: left;
        margin-top: 0rem;
        margin-bottom: 0.5rem;
    }

    .financeButton {
        margin-top: 0rem;
        margin-bottom: 3rem;
    }
}