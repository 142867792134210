.overlay_background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 8;

}

.overlay_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    z-index: 11;
    padding: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #3a905ce0;
    border-radius: 10px;
    box-shadow: 0px 4px 4px #00000040;
    height: 150px;
    width: 278px;

}

.overlay_container p {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 211px;
    margin: auto;

}