.container {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 90%;
}

.container h1 {
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.19rem;
    font-weight: 800;
    max-width: 85%;
    padding: 0rem 2.5rem;
    text-align: left;
}

th {
    background-color: #5395fb;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 1rem 2rem;
}

td {
    color: #4e4848;
    font-family: "Inter-Bold", Helvetica;
    font-size: 0.94rem;
    font-weight: 700;
    padding: 1.5rem 2rem;
}

table {
    width: 70%;
    margin: 2rem auto;
    border-radius: 10px;
    border-collapse: collapse;
    border-spacing: 0;
}

table th:first-child {
    border-radius: 10px 0 0 0;
}

table th:last-child {
    border-radius: 0 10px 0 0;
}

.rowOne td {
    background-color: #f3f9fd;
}

table,
th,
td {
    text-align: center;
}

.textLink {
    text-decoration: none;
}

.container h2 {
    color: #7e7e7e;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    margin: 1rem auto 1rem;
}

.button1 {
    background-color: #1972f9;
    border-radius: 5px;
    display: block;
    margin: 0rem auto;
    border-style: none;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0.9rem 2rem;
    width: 100%;
}

.pageLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    width: 100%;
    height: 0.5rem;
}

.line {
    border-bottom: 2px solid #464545;
    margin: auto;
    height: 0.13rem;
    width: 40%;
}

.pageTwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 100%;
    min-height: 30vh;
    margin-bottom: 3rem;
    padding: 2rem;
}

.pageTwoColA {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    height: fit-content;
    margin-bottom: 2rem;
}

.pageTwoColB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    height: fit-content;
}

.divt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 30%;
    max-width: 30%;
    min-height: 100%;
    padding: 0.1rem;
}

.divt h3 {
    color: #7e7e7e;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 1.25rem;
    font-weight: 600;
}

.divt img {
    width: 35%;
    max-width: 35%;
    height: auto;
    object-fit: contain;
    padding-top: 0.5rem;
}

.lastButton {
    background-color: #aba9a9;
    border: 1px solid;
    border-color: #f0ebeb;
    border-radius: 40px;
    border-style: none;
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 700;
    cursor: pointer;
    padding: 1rem 2.5rem;
}


@media screen and (max-width: 760px) {
    .container h1 {
        width: 90%;
        max-width: 90%;
    }

    .pageTwo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 100%;
        min-height: 20vh;
        margin-top: 1vh;
        margin-bottom: 0rem;
        padding: 0.5rem;
    }

    .pageTwoColA {
        height: fit-content;
        margin-bottom: 1vh;
        margin-top: 1vh;
    }

    .pageTwoColB {
        height: fit-content;
    }

    .divt {
        padding: 0rem;
    }
}