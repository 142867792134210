:root {
    --primary: #1972F9;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Inter-Regular", Helvetica;
    color: #fff;
    cursor: pointer;
    background-color: var(--primary);
}

.btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: rgb(0, 0, 0);
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btns {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Inter-Regular", Helvetica;
    color: #fff;
    cursor: pointer;
    background-color: black;
}

.btns:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: rgb(0, 0, 0);
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
}