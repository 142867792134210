.form {
    width: 100%;
    max-width: 100%;
}

.form input[type=text] {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #0000002e;
    border-radius: 10px;
    width: 90%;
    max-width: 90%;
    padding: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.form input[type=email] {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #0000002e;
    border-radius: 10px;
    width: 90%;
    max-width: 90%;
    padding: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.form input[type=tel] {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #0000002e;
    border-radius: 10px;
    width: 90%;
    max-width: 90%;
    padding: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.form input[type=submit] {
    background-color: #1972f9;
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.25rem;
    padding: 0.8rem 4rem;
    white-space: nowrap;
    color: #ffffff;
    cursor: pointer;
    margin-top: 3rem;
    width: 90%;
    max-width: 90%;
}

.form textarea {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #0000002e;
    border-radius: 10px;
    width: 90%;
    min-height: 20vh;
    max-width: 90%;
    padding: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

::placeholder {
    color: #ada4a4;
    font-family: "Inter-Regular", Helvetica;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: left;
}

.successRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    height: 40px;
}

.successRow img {
    height: 50px;
    width: 50px;
    object-fit: cover;
}

.successRow h1 {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    width: 137px;
    height: 37px;
    margin: 7px auto;
}

.form p {
    font-family: "Inter-Regular", Helvetica;
    font-size: 0.6rem;
    text-align: left;
    padding-left: 2rem;
    color: tomato;
}

.sidebarSpace {
    width: inherit;
    background-color: blueviolet;
}

.space {
    align-items: center;
    background-color: #e7e2f8;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    padding: 10px;
    /* position: relative; */
    width: 80%;
}