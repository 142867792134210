.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
}

.card {
    margin-top: 1rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 4rem;
    background-color: #d9d9d933;
}

.card h1 {
    margin-top: 2rem;
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 3.13rem;
    font-weight: 700;
}

.cardRow {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    width: 100%;
    max-width: 100%;
    height: fit-content;
    align-items: center;
}

.crImage {
    width: 53%;
    max-width: 53%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.crContent {
    width: 45%;
    max-width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 760px) {
    .cardRow {
        flex-direction: column;
    }


    .crImage {
        display: none;
    }

    .crContent {
        width: 100%;
        max-width: 100%;
    }


}