.container {
    background-color: #1f282e;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 3.5rem;
    width: 100%;
    min-height: 50vh;
}

.row1 {
    width: 40%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row1 h3 {
    font-family: "Inter-Bold", Helvetica;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    text-align: left;
}

.mobileLogo {
    display: none;
}

.row1 p {
    font-family: "Inter-Bold", Helvetica;
    color: #FFFFFF;
    font-size: 0.98rem;
    font-weight: 400;
    text-align: left;
    padding-bottom: 1.5rem;
}

.row1Image {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-start;
}

.row1Image img {
    object-fit: contain;
    max-width: 100%;
    height: 100%;
}

.row2 {
    width: 20%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.row2 h3 {
    font-family: "Inter-Bold", Helvetica;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    text-align: left;
}

.footList {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footList li {
    list-style: none;
    white-space: nowrap;
    color: #FFFFFF;
    padding: 0.5rem 0;
    font-family: "Inter-Bold", Helvetica;
    font-size: 0.95rem;
    font-weight: 500;
}

.row3 {
    width: 30%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row3 h3 {
    font-family: "Inter-Bold", Helvetica;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    text-align: left;
}

.col {
    display: flex;
    flex-direction: column;
}

.textLink {
    text-decoration: none;
}

.thumbnails {
    padding: 1rem 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
}

@media screen and (max-width: 960px) {
    .container {
        background-color: #1f282e;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2.5rem 3.5rem;
        width: 100%;
        min-height: 50vh;
    }

    .mobileLogo {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 3.5rem;
    }

    .mobileLogo img {
        object-fit: contain;
        max-width: 35%;
        height: 40%;
        margin-left: -1.2rem;
    }

    .row1 {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .row1 h3 {
        font-family: "Inter-Bold", Helvetica;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    .row1 p {
        font-family: "Inter-Bold", Helvetica;
        color: #FFFFFF;
        font-size: 1.25rem;
        font-weight: 400;
        text-align: left;
        margin-bottom: 2.25rem;
    }

    .row2 {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .row2 h3 {
        font-family: "Inter-Bold", Helvetica;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 2.25rem;
        margin-bottom: 0.25rem;
        text-align: left;
    }

    .footList li {
        list-style: none;
        white-space: nowrap;
        color: #FFFFFF;
        font-family: "Inter-Bold", Helvetica;
        font-size: 1.25rem;
        font-weight: 500;
    }

    .row1Image {
        display: none;
    }

    .row3 {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .row3 h3 {
        font-family: "Inter-Bold", Helvetica;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 2.25rem;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    .col h3 {
        margin-top: 2.5rem;
    }

    .thumbnails {
        padding: 1rem 0.5rem 0.5rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 65%;
        margin-bottom: 1rem;
    }

}