.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
}

.textLink {
    text-decoration: none;
}

.headerOne {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    margin-top: 3rem;
    padding: 0rem 2rem;
}

.headerOne h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.9rem;
    font-weight: 900;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.headerOne p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-top: 0rem;
}

.cardOne {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 2rem;
    background-color: #eaeaea;
}

.cardOneContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: flex-start;
}

.cardOneContent h2 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
}

.cardOneContent p {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-bottom: 1rem;
}

.cardOneImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardOneImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardTwo {
    margin-top: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 2.5rem;
    background-color: #ffffff;
}

.cardTwoContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    justify-content: flex-start;
}

.cardTwoContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.cardTwoContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 0rem;
    margin-bottom: 1rem;
}

.ctcList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.ctcList li {
    list-style: none;
    color: #000000;
    padding: 0.5rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
}

.cardTwoImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTwoImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.headerTwo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 3rem;
    padding: 1rem;
}

.headerTwo h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
}

.cardThree {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    max-width: 100%;
    padding: 2rem 2.5rem;
    margin-bottom: 5rem;
}

.cardRowA {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.miniRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 1.25rem;
    width: 30%;
    max-width: 30%;
    background-color: #f7f7f7;
    border-radius: 20px;
}

.miniRow p {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Inter-Regular", Helvetica;
}

.lastHeader {
    background-color: #234d31e6;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5rem;
    padding: 2rem 2rem;
}

.lastHeader button {
    background-color: #ffffff;
    border-radius: 5px;
    border-style: none;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 2.25rem;
    padding: 0.8rem 4rem;
    white-space: nowrap;
    color: #050a24;
    display: block;
}

@media screen and (max-width: 760px) {
    .headerOne {
        margin-top: 1rem;
        padding: 0rem 2rem;
    }

    .headerOne h1 {
        font-size: 2.5rem;
    }

    .cardOne {
        margin-top: 2rem;
        flex-direction: column;
    }

    .cardOneContent {
        width: 100%;
        max-width: 100%;
    }

    .cardOneContent h2 {
        margin-bottom: 1.5rem;
    }

    .cardOneContent p {
        margin-bottom: 1rem;
    }

    .cardOneImage {
        width: 80%;
        max-width: 80%;
        margin-top: 0.5rem;
        margin-bottom: 4rem;
    }

    .cardTwo {
        margin-top: 2rem;
        flex-direction: column;
    }

    .cardTwoContent {
        width: 100%;
        max-width: 100%;
    }

    .cardTwoContent h1 {
        margin-bottom: 1.5rem;
    }

    .cardTwoContent p {
        margin-bottom: 1rem;
    }

    .cardTwoImage {
        width: 80%;
        max-width: 80%;
        margin-top: 1.5rem;
    }

    .cardRowA {
        flex-direction: column;
        justify-content: space-between;
    }

    .miniRow {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }


}