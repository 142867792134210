.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
}

.textLink {
    text-decoration: none;
}

.headerOne {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    max-width: 100%;
}

.headerOne h1 {
    margin-top: 3rem;
    width: 100%;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    text-align: left;
    font-size: 3rem;
    font-weight: 800;
}

.pageOneRow {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
}

.rowContent {
    width: 50%;
    max-width: 50%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.rowContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: left;
}

.rowImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowImage img {
    width: 70%;
    max-width: 70%;
    object-fit: contain;
}

.contentImage {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.contentImage img {
    width: 90%;
    max-width: 90%;
    object-fit: contain;
}

.cardOne {
    margin-top: 4rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    width: 100%;
    max-width: 100%;
    padding: 3rem 4rem;
}

.cardOne h1 {
    font-size: 2rem;
    font-weight: 900;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    margin-bottom: 2.5rem;
    text-align: center;
    width: 70%;
    max-width: 70%;
}

.cardOne p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
    margin-top: 0rem;
    text-align: left;
}

.cardRow {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.cardContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
}

.cardContent h3 {
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.25rem;
    font-weight: 800;
    margin-bottom: 1rem;
    text-align: left;
}

.cardContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.cardImage {
    width: 45%;
    max-width: 45%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.cardList li {
    color: #000000;
    padding: 0.8rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    margin-left: 1rem;
    text-align: left;
}

.cardTwo {
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    margin-top: 4rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 3rem 4rem;
}

.cardTwoImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTwoImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardTwoContent {
    width: 48%;
    max-width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: flex-start;
}

.cardTwoContent h1 {
    font-family: "Inter-ExtraBold", Helvetica;
    color: #000000;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
}

.cardTwoContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.cardTwoList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
}

.cardTwoList li {
    color: #000000;
    padding: 0.8rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    margin-left: 1.5rem;
    text-align: left;
}

.cardThree {
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.cardThreeImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardThreeImage img {
    width: 90%;
    max-width: 90%;
    object-fit: contain;
}

.cardThreeContent {
    width: 48%;
    max-width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: flex-start;
}

.cardThreeContent h1 {
    font-family: "Inter-ExtraBold", Helvetica;
    color: #000000;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
}

.cardThreeContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 0rem;
}

.cardThreeList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 0.75rem;
}

.cardThreeList li {
    color: #000000;
    padding: 0.8rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    margin-left: 1.5rem;
    text-align: left;
}

.cardFour {
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.cardFourImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardFourImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardFourContent {
    width: 48%;
    max-width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: flex-start;
}

.cardFourContent h1 {
    font-family: "Inter-ExtraBold", Helvetica;
    color: #000000;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
}

.cardFourContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.cardFourList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
}

.cardFourList li {
    color: #000000;
    padding: 0.8rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    margin-left: 1.5rem;
    text-align: left;
}

.cardFive {
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    margin-top: 3rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.cardFiveImage {
    width: 46%;
    max-width: 46%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardFiveImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardFiveContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: flex-start;
}

.cardFiveContent h1 {
    font-family: "Inter-ExtraBold", Helvetica;
    color: #000000;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 2.5rem;
}

.cardFiveContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.cardFiveList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 1rem;
}

.cardFiveList li {
    color: #000000;
    padding: 0.8rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    margin-left: 1.5rem;
    text-align: left;
}

.headerTwo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 3rem;
}

.headerTwo h1 {
    width: 70%;
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 800;
}

.headerTwo p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: left;
}

.cardSix {
    background-color: #e2e2e2;
    height: fit-content;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 3rem;
}

.miniCard {
    width: 32%;
    max-width: 32%;
    background-color: #ffffff;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
}

.miniCardHeader {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.miniCardHeader img {
    width: 20%;
    max-width: 20%;
    object-fit: contain;
}

.miniCardHeader h3 {
    width: 75%;
    max-width: 75%;
    text-align: left;
    color: #122dbf;
    font-size: 1.56rem;
    font-weight: 600;
    font-family: "Inter-SemiBold", Helvetica;
}

.miniCard p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
}

.commButton {
    margin: auto;
    background-color: #1972f9;
    border-radius: 5px;
    padding: 0.8rem 4rem;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
    border-style: none;
    margin: 4rem;
    display: block;
}

@media screen and (max-width: 760px) {

    .headerOne h1 {
        margin-top: 1rem;
        width: 100%;
        text-align: left;
        font-size: 3rem;
    }

    .pageOneRow {
        width: 100%;
        max-width: 100%;
        margin-top: 2rem;
    }

    .contentImage {
        display: none;
    }

    .cardOne {
        margin-top: 2rem;
        padding: 3rem;
    }

    .cardOne h1 {
        margin-bottom: 1rem;
        width: 100%;
        max-width: 100%;
    }

    .cardRow {
        margin-top: 1rem;
        flex-direction: column;
        margin-bottom: 0rem;
    }

    .cardImage {
        width: 90%;
        max-width: 90%;
        margin-top: 4rem;
    }

    .cardContent {
        margin-top: 3rem;
        width: 100%;
        max-width: 100%;
    }

    .cardContent h3 {
        font-size: 2.25rem;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    .cardContent p {
        color: #000000;
        font-family: "Inter-Regular", Helvetica;
        font-size: 1.25rem;
        font-weight: 400;
    }

    .cardTwo {
        margin-top: 3rem;
        padding: 3rem;
        flex-direction: column-reverse;
    }

    .cardTwoImage {
        width: 80%;
        max-width: 80%;
        margin-top: 3.5rem;
    }

    .cardTwoContent {
        width: 100%;
        max-width: 100%;
    }

    .cardTwoContent h1 {
        font-family: "Inter-ExtraBold", Helvetica;
        color: #000000;
        font-size: 2.5rem;
        font-weight: 800;
        margin-bottom: 1.5rem;
    }

    .cardTwoContent p {
        color: #000000;
        font-family: "Inter-Regular", Helvetica;
        font-size: 1.25rem;
        font-weight: 400;
    }

    .cardThree {
        margin-top: 3rem;
        flex-direction: column;
    }

    .cardThreeImage {
        width: 85%;
        max-width: 85%;
        margin-top: 2rem;
    }

    .cardThreeContent {
        width: 100%;
        max-width: 100%;
    }

    .cardThreeContent h1 {
        font-size: 2.75rem;
        margin-bottom: 1rem;
    }

    .cardThreeContent p {
        margin-top: 0rem;
    }

    .cardFour {
        margin-top: 3rem;
        flex-direction: column-reverse;
    }

    .cardFourImage {
        width: 80%;
        max-width: 80%;
    }

    .cardFourContent {
        width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .cardFourContent h1 {
        font-size: 2.75rem;
        margin-bottom: 1.5rem;
    }

    .cardFourContent p {
        margin-top: 0rem;
    }

    .cardFive {
        flex-direction: column;
    }

    .cardFiveImage {
        width: 80%;
        max-width: 80%;
        margin-top: 1rem;
    }

    .cardFiveContent {
        width: 100%;
        max-width: 100%;
    }

    .cardFiveContent h1 {
        margin-bottom: 1.5rem;
    }

    .cardFiveContent p {
        margin-top: 0rem;
    }

    .headerTwo h1 {
        width: 90%;
    }

    .headerTwo p {
        margin-top: 0rem;
    }

    .cardSix {
        flex-direction: column;
    }

    .miniCard {
        width: 100%;
        max-width: 100%;
        padding: 2rem;
        margin-bottom: 1rem;
    }

    .miniCard p {
        text-align: left;
    }

}