.container {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    max-width: 90%;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.textLink {
    text-decoration: none;
}

.pageOne {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    width: 67%;
    margin-top: 1.5rem;
}

.pageOne h1 {
    font-family: "Inter-Black", Helvetica;
    font-size: 3.5rem;
    font-weight: 900;
}

.pageOne p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 1rem;
}

.pageOne button {
    margin-top: 2rem;
    background-color: #1972f9;
    border-style: none;
    border-radius: 5px;
    padding: 0.75rem 2rem;
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;
    display: block;
}

.menuOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 3rem;
}

.menuOneContent {
    width: 50%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    margin-top: 0;
}

.menuOneContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.35rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
}

.menuOneContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.3rem;
    font-weight: 400;
}

.menuOneImage {
    width: 45%;
    max-width: 45%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuOneImage img {
    max-width: 100%;
    object-fit: contain;
}

.contentImage {
    width: 100%;
    max-width: 100%;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    text-align: right;
}

.contentImage img {
    width: 65%;
    max-width: 65%;
    object-fit: contain;
}

.cardOne {
    margin-top: 4rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    padding: 3rem;
    margin-bottom: 1rem;
}

.cardImage {
    width: 48%;
    max-width: 48%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    text-align: right;
}

.cardImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardContent {
    width: 48%;
    max-width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    margin-top: 0;
}

.cardContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 2rem;
}

.cardContent p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.cardTwo {
    margin-top: 4rem;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    padding: 3rem 1.5rem;
    margin-bottom: 1rem;
}

.cardTwoSideA {
    width: 55%;
    max-width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
}

.cardTwoSideA h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
}

.cardTwoSideA p {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 1.25rem;
    font-weight: 400;
}

.sideAImage {
    width: 65%;
    max-width: 65%;
    height: 40%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.sideAImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardTwoSideB {
    width: 42%;
    max-width: 42%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    background-color: #f7f7f7;
    border-radius: 40px 40px 0px 40px;
    padding: 1% 2%;
}

.bList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin: auto;
}

.bList li {
    color: #000000;
    padding: 1rem 0;
    font-size: 1.25rem;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
}

.cardThree {
    margin-top: 2rem;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    max-width: 100%;
    padding: 3rem 2rem;
}

.miniCardThree {
    width: 90%;
    max-width: 90%;
    background-color: rgb(250, 249, 249);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.mctContent {
    width: 45%;
    /* max-width: 45%;
    min-height: inherit; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
}

.mctContent h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    margin-top: 0rem;
}

.mctImage {
    width: 55%;
    max-width: 55%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mctImage img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.cardThreeList {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

.cardThreeList li {
    list-style: none;
    font-size: 1.25rem;
    padding: 1rem 0;
    color: #000000;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 600;
}

.cardFour {
    margin-top: 4rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0px 4px 4px #00000040;
    min-width: 100%;
    padding: 3.5rem 1.5rem;
    margin-bottom: 4rem;
}

.cardFour h1 {
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 2.5rem;
    font-weight: 800;
    width: 70%;
}

.cardRowA {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
}

.miniRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 30%;
    max-width: 30%;
    min-height: 20vh;
    background-color: #f7f7f7;
    border-radius: 20px;
}

.miniRow p {
    color: #000000;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 0.94rem;
    font-weight: 600;
}

.cardFour button {
    background-color: #1972f9;
    border-radius: 5px;
    border-style: none;
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-weight: 700;
    text-align: center;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    margin: 4rem auto;
    display: block;
    width: 40vw;
}


@media screen and (max-width: 760px) {
    .pageOne {
        width: 100%;
    }

    .pageOne h1 {
        font-family: "Inter-Black", Helvetica;
        font-size: 3.5rem;
        font-weight: 900;
        text-align: left;
    }

    .pageOne P {
        text-align: left;
    }

    .menuOne {
        flex-direction: column;
        justify-content: flex-start;
    }

    .menuOneContent {
        width: 100%;
        max-width: 100%;
        justify-content: space-between;
    }

    .contentImage {
        display: none;
    }

    .menuOneImage {
        margin-top: 1rem;
        width: 75%;
        max-width: 75%;
    }

    .cardOne {
        flex-direction: column-reverse;
        justify-content: flex-start;
        margin-top: 3rem;
    }

    .cardImage {
        width: 80%;
        max-width: 80%;
        margin-top: 2rem;
    }

    .cardContent {
        width: 100%;
        max-width: 100%;
    }

    .cardContent h1 {
        margin-bottom: 1.5rem;
    }

    .cardTwo {
        margin-top: 3rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 40px;
        box-shadow: 0px 4px 4px #00000040;
        min-width: 100%;
    }

    .cardTwoSideA {
        width: 100%;
        max-width: 100%;
    }

    .sideAImage {
        display: none;
    }

    .cardTwoSideB {
        margin-top: 2.5rem;
        width: 70%;
        max-width: 90%;
    }

    .miniCardThree {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
    }

    .mctContent {
        margin-top: 3.5rem;
        width: 100%;
        max-width: 100%;
    }

    .mctContent h1 {
        font-size: 3rem;
    }

    .mctImage {
        width: 90%;
        max-width: 90%;
    }

    .cardThreeList {
        margin-top: 1rem;
    }
}