* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: "Inter-Regular", Helvetica;

}

html {
  color: #000;
  font-size: calc(0.78vw + 0.785vh);
}

body {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

button {
  cursor: pointer;
}